/** 
 * Form and button styles
 */

label {
  font-size: 14px !important;
  margin-bottom: 0;
}

.custom-control:not(.custom-switch) {
  .custom-control-label {
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 5px;
    &::before,
    &::after {
      top: 2px;
    }
    &::before {
      border: 1px solid #cfd6e4;
    }
  }
}
.custom-control-input {
  &:checked {
    ~ .custom-control-label::before {
      border-color: #3b54ec;
    }
  }
}

.custom-checkbox {
  min-height: auto;
  .custom-control-label {
    &::before {
      border-radius: 0.15rem !important;
    }
    &::after {
      left: -1.5rem;
    }
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label::before {
        background: #3b54ec;
      }
      ~ .custom-control-label::after {
        background-image: url(../../images/icons/checkbox-checked.svg);
      }
    }
    &[data-indeterminate='true'] {
      ~ .custom-control-label::before {
        background: linear-gradient(180deg, #f5f6f7 50%, #f5f6f7 100%), #ffffff;
        box-shadow: inset 0px -1px 1px rgba(67, 90, 111, 0.04);
      }
      ~ .custom-control-label::after {
        background-image: url(../../images/icons/checkbox-indeterminate.svg);
      }
    }
    &:disabled:checked ~ .custom-control-label::before {
      background-color: $color-primary;
      opacity: 0.4;
    }
  }
}

.btn {
  font-size: 14px;
  font-weight: 500;
  height: 42px;
  border-radius: 8px;
  padding: 8px 13px;
  line-height: 0;
  svg {
    margin-right: 4px;
  }
  span {
    position: relative;
    top: 1px;
  }
}

.btn-link {
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.btn-sm,
.btn-group-sm > .btn {
  height: 34px;
  padding: 5px 10px;
  border-radius: 6px;
  line-height: 0;
}

.btn-outline-light {
  color: $color-body !important;
  background: #fff; // rgba(249, 249, 251, 1)
  border: 1px solid rgba(0, 0, 0, 0.08);
  &:hover {
    background: rgba($color-primary, 0.1);
    border: 1px solid rgba($color-primary, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    color: $color-primary !important;
  }
}

.btn.icon {
  &.rounded {
    border-radius: 100px !important;
  }
  svg {
    margin: 0;
  }
}

.form-text {
  margin-top: 0;
}

.invalid-feedback {
  display: block;
  font-size: 90%;
}

.form-group {
  margin: 30px 0;
  &.row {
    .col,
    .col-md-12 {
      padding: 0;
    }
    .col-md-3,
    .col-md-4,
    .col-md-6:first-child {
      padding-left: 0;
    }
    .col-md-8,
    .col-md-9,
    .col-md-6:last-child {
      padding-right: 0;
    }
  }
}

.form-control {
  border-radius: 8px;
  font-size: 14px;
  height: 42px;
  border-color: $color-border;
  padding-bottom: 0.45rem;
  &:disabled,
  &[readonly] {
    background-color: $color-background-light;
    opacity: 1;
  }
}

.input-group {
  flex-wrap: nowrap;
  .react-datepicker-wrapper:first-child .form-control {
    border-radius: 6px 0 0 6px;
  }
  .react-datepicker-wrapper:last-child .form-control {
    border-left: 0;
    border-radius: 0 6px 6px 0;
  }
  .form-control-sm.date {
    width: 100px;
  }
}

.input-group-text {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0.45rem;
  border-color: $color-border;
  background: $color-background-light;
}

.form-control-sm {
  border-radius: 6px;
  height: 34px !important;
  padding: 5px 10px 7px;
}

select {
  &.form-control {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(../../images/icons/caret.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
    background-size: 12px;
    padding-right: 30px !important;
  }
}

.search.form-control {
  background-image: url(../../images/icons/search.svg);
  background-repeat: no-repeat;
  background-position: 8px calc(100% - 9px);
  background-size: 16px;
  padding-left: 30px !important;
}
