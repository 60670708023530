$system-font: 'Inter', system, -apple-system, system-ui, BlinkMacSystemFont,
  Helvetica Neue, Helvetica, Lucida Grande, sans-serif !important;

$brand-font: 'Inter', system-ui, 'Helvetica';

$color-body: #171c34;
$color-link: #262626;
$color-primary: #3b54ec;
$color-secondary: #171c34;
$color-secondary-dark: #0c1326;
$color-accent: #23cbfd;
$color-success: #24a148;
$color-danger: #da1e28;

$color-background: #f2f3f5;
$color-background-light: #f8f9fb;
$color-border-light: #f2f3f5;
$color-border: #edeef0;
$color-gray-light: #c3c8cc;
$color-gray: #878f94;

$sidebar-width: 240px;
$sidebar-mini-width: 60px;
