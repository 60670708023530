@import '../variables.scss';

// utils
.w-auto {
  width: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.action-link {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-link;
  cursor: pointer;
  transition: all 300ms;
  &.muted {
    color: #262626;
  }
  &:hover {
    color: $color-primary;
  }
}

.text-currency {
  font-family: $system-font;
}

.text-muted {
  color: #808a97 !important;
}

.text-link {
  transition: color 300ms;
  &:hover {
    color: $color-primary;
    cursor: pointer;
  }
}

.text-truncated {
  display: block;
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-weight-medium {
  font-weight: 500;
}

.text-small-title {
  color: #808a97;
  font-size: 13px;
  display: block;
  font-weight: 500;
  padding-bottom: 2px;
}

.hidden-input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.spinner {
  background-image: url(../../images/icons/icon-spinner.svg);
  background-repeat: no-repeat;
  background-size: 36px;
  width: 36px;
  height: 36px;
  display: block;
  margin-bottom: 0;
  background-position: center;
  &.small {
    width: 24px;
    height: 24px;
    background-size: 24px;
  }
  &.button {
    width: 24px;
    height: 24px;
    background-size: 24px;
    position: relative;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
  }
  &.primary {
    background-image: url(../../images/icons/icon-spinner-primary.svg);
  }
  &.white {
    background-image: url(../../images/icons/icon-spinner-white.svg);
  }
}

.sticky-top {
  top: 80px;
  z-index: 99;
}
