// .dropdown-toggle {
//   &.btn-link {
//     color: $color-gray;
//     padding-left: 0;
//     padding-right: 0;
//   }
//   svg {
//     margin: 0 4px 0 0;
//   }
//   &::after {
//     display: none;
//   }
//   &:focus {
//     outline: 0;
//     box-shadow: none !important;
//   }
// }

.dropdown-menu {
  border: 1px solid $color-border;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px;
  margin-top: 5px;
  .dropdown-item {
    padding: 6px 10px;
    border-radius: 6px;
    &:active {
      background-color: $color-background-light;
      color: $color-body;
    }
    svg {
      color: $color-body;
      position: relative;
      top: -1px;
      margin-right: 4px;
      width: 20px;
    }
  }
}
