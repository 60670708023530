/**
 * Fonts, base styles and layout stuff
 */

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url(../fonts/Inter-Regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url(../fonts/Inter-Medium.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(../fonts/Inter-SemiBold.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(../fonts/Inter-Bold.woff2);
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  background-color: #fff !important;
  padding: 0;
  margin: 0;
  font-weight: normal;
  color: $color-body;
  font-family: $brand-font;
  font-size: 14px;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.modal-open {
    overflow: auto !important;
  }
}

small,
.small {
  font-size: 90%;
}

.app {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar {
  background: #fff;
  border-bottom: 1px solid #f1f2f3;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.01);
}

.navbar-brand {
  font-weight: 500;
  font-size: 18px;
}

.page-wrapper {
  width: 100%;
  margin: 0;
  transition: all 400ms ease-in-out;
  transition-delay: 100ms;
  &.with-sidebar {
    width: calc(100% - #{$sidebar-width});
    margin-left: $sidebar-width;
  }
  &.with-mini-sidebar {
    width: calc(100% - #{$sidebar-mini-width});
    margin-left: $sidebar-mini-width;
  }
}

.page-container {
  margin: 20px 10px 40px;
}

@media (max-width: 768px) {
  .page-wrapper {
    width: 100%;
    margin: 0;
  }
}
