@import '../../../assets/sass/variables.scss';

.sidebar {
  background: $color-background-light;
  min-width: $sidebar-width;
  width: $sidebar-width;
  height: 100vh;
  margin-left: -$sidebar-width;
  position: fixed;
  top: 0;
  left: 0;
  // padding: 15px 0 0;
  overflow: hidden;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.01);
  transition: all 0.4s;

  &:not(.mini) {
    z-index: 1000;
  }

  &.open {
    margin-left: 0;
    z-index: 1000;
  }

  .close-icon {
    background: #0f1a36;
    border-radius: 4px;
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
    padding: 4px 8px;
    transform: translateX(0);
    transition: all 300ms;
    z-index: 10;
    &:hover {
      background: rgba(35, 203, 253, 0.2);
      transform: translateX(-6px);
    }
  }

  :global .nav {
    flex-wrap: nowrap;
    overflow: scroll;
    height: calc(100% - 50px);
  }

  :global .nav-link {
    color: #cdd0d6; // #0C1326
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 0.3rem 1.3rem;
    > * {
      transition: all 200ms;
    }
    svg {
      width: 16px;
      height: 16px;
      stroke: $color-gray;
      margin-right: 4px;
    }
    span {
      position: relative;
      left: 10px;
    }
    &:hover {
      color: #fff;
      svg {
        stroke: #fff;
      }
    }
    &.active {
      color: $color-accent;
      font-weight: 500;
      svg {
        stroke: $color-accent;
      }
    }
    &.disabled {
      svg {
        opacity: 0.3;
      }
    }
  }

  .nav-dummy {
    margin-top: 10px;
    .nav-link-dummy {
      background-color: rgba(98, 121, 143, 0.25);
      border-radius: 4px;
      display: block;
      height: 22px;
      margin: 0 20px 12px;
    }
  }
}

.sidebar.mini {
  min-width: $sidebar-mini-width;
  width: $sidebar-mini-width;
  margin-left: -$sidebar-mini-width;
  padding-top: 15px;
  transition: all 400ms ease-in-out;
  transition-delay: 350ms;

  &.open {
    margin-left: 0;
  }

  .header {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .logo {
      width: 20px;
    }
  }
  :global .nav-link {
    margin: 10px 0;
    padding: 8px;
    transition: all 400ms ease-in-out;
    svg {
      width: 20px;
      height: 20px;
      margin: 0 auto;
    }
    &:hover,
    &.active {
      background: rgba(35, 203, 253, 0.1);
    }
  }
}
