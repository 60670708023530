.pagination {
  justify-content: center;
  .page-item {
    margin: 0 6px;
    .page-link {
      font-family: $system-font;
      font-weight: 500;
      text-align: center;
      border-radius: 6px;
      color: $color-body;
      padding-bottom: 0.6rem;
      transition: all 400ms ease;
      &:hover,
      &:focus {
        color: $color-primary;
        background-color: rgba($color-primary, 0.1);
        border-color: rgba($color-primary, 0.1);
        box-shadow: none;
      }
    }
    &.active .page-link {
      color: #fff;
      background-color: $color-primary;
      border-color: $color-primary;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
}