.card {
  background: #fff;
  border: 1px solid $color-border-light;
  border-radius: 6px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  transition: all 100ms ease-in-out;
  &.centered {
    margin: 0 auto;
    max-width: 600px;
  }
  &.bordered {
    border: 2px solid $color-border;
  }
}

.card-header {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f1f2f3;
  border-radius: 8px 8px 0 0 !important;
  display: flex;
  justify-content: space-between;
  &.odd,
  &.filters {
    background: #f9f9fb;
    border-radius: 0 !important;
    padding-top: 12px;
    flex-wrap: nowrap;
  }
}

.card-footer {
  border-color: #f1f2f3;
}

.card-title {
  font-size: 14px;
  font-weight: 500;
  margin: 2px 0 0;
}

.card-body {
  position: relative;
  &::after {
    content: '';
    background: rgba(255, 255, 255, 1);
    opacity: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    visibility: hidden;
  }
  &.disabled::after {
    opacity: 0.7;
    visibility: visible;
    z-index: 10;
  }
}

.card-footer {
  background: #fff;
  border-top: 1px solid #f6f6f6;
  border-radius: 0 0 8px 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-container {
  display: grid !important;
  grid-template-columns: 30% 70%;
  grid-template-areas: 'sidebar' 'main';
  overflow: hidden;
  &.reversed {
    grid-template-areas: 'main' 'sidebar';
    grid-template-columns: 70% 30%;
  }
  &.three-part {
    grid-template-columns: 33% 33% 33%;
    grid-template-areas: 'main' 'sidebar';
  }
  .card,
  .card-header {
    border-radius: 0;
  }
  .card {
    border: 0;
    box-shadow: none !important;
  }
}

// grid areas
.card-container {
  .card-main {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    grid-area: main;
    grid-row: 1;
    grid-column: 2;
    &.fixed > .card-body {
      max-height: 600px;
      overflow-y: scroll;
    }
  }
  .card-sidebar {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    border-right: 1px solid #f1f2f3;
    grid-area: sidebar;
  }
  &.reversed {
    .card-main {
      border-right: 1px solid #f1f2f3;
      grid-column: 1;
    }
    .card-sidebar {
      border: 0;
      grid-row: 1;
      grid-column: 2;
    }
  }

  .card-left,
  .card-center,
  .card-right {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    grid-area: main;
    &.fixed > .card-body {
      max-height: 500px;
      overflow-y: scroll;
    }
  }
  .card-center {
    grid-row: 1;
    grid-column: 2;
    border-left: 1px solid #f1f2f3;
    border-right: 1px solid #f1f2f3;
  }
  .card-right {
    grid-row: 1;
    grid-column: 3;
  }
}
