.badge {
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
}

.badge:not(.flag) {
  &.badge-success {
    background-color: rgba(36, 161, 72, 0.1);
    color: $color-success;
  }
  &.badge-danger {
    background-color: rgba(218, 30, 40, 0.1);
    color: $color-danger;
  }
  &.badge-warning {
    background-color: #fff3cd;
    color: #856404;
  }
}

.badge.flag {
  padding-left: 22px;
  position: relative;
  background-color: transparent;
  border: 1px solid $color-border;
  color: $color-body;
  &:before {
    background-color: $color-accent;
    border-radius: 10px;
    content: ' ';
    display: block;
    position: absolute;
    left: 6px;
    top: 6px;
    margin-right: 8px;
    height: 10px;
    width: 10px;
  }
  &.badge-success {
    &:before {
      background-color: $color-success;
    }
  }
  &.badge-danger {
    &:before {
      background-color: $color-danger;
    }
  }
  &.badge-general {
    &:before {
      background-color: $color-primary;
    }
  }
  &.badge-other {
    &:before {
      background-color: #b2586e;
    }
  }
}

.badge.premium {
  background: rgba(35, 201, 251, 0.1);
  border: 1px solid rgba(35, 201, 251, 0.1);
  color: #007fa5;
  svg {
    position: relative;
    top: -1px;
    margin-right: 4px;
  }
}

.status-dot {
  background-color: $color-success;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 0;
  margin-right: 8px;
  height: 18px;
  width: 18px;
  font-size: 10px;
  font-weight: 600;
  code {
    color: #fff;
    position: relative;
    left: 0.4px;
  }
  &.danger {
    background-color: $color-danger;
  }
}
