.filter-dropdown {
  .dropdown-toggle {
    // color: $color-primary;
    // padding-left: 0;
    // padding-right: 0;
    // svg {
    //   margin: 0 4px 0 0;
    // }
    &::after {
      display: none;
    }
    &:focus {
      outline: 0;
      box-shadow: none !important;
    }
  }

  .dropdown-menu {
    border: 1px solid $color-border;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    padding: 5px 20px;
    width: 300px;
    &.wide {
      width: 600px;
    }
  }
  .dropdown-menu-right {
    &::after {
      left: auto;
      right: 8%;
    }
  }

  .dropdown-menu {
    .filter-group {
      margin: 15px 0;
    }
    // label,
    // .filter-label {
    //   display: block;
    //   font-size: 95%;
    //   font-weight: 500;
    //   margin-bottom: 2px;
    // }
  }
  .input-group {
    .form-control-sm.date {
      width: 100%;
    }
  }

  .options-group {
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
    .option {
      background-color: $color-background-light;
      border: 1px solid $color-border-light;
      border-radius: 100px;
      padding: 2px 10px;
      font-size: 13px;
      margin-right: 5px;
      transition: all 300ms;
      &:hover {
        background-color: $color-background;
        cursor: pointer;
      }
      &.active {
        background-color: rgba($color-primary, 0.1);
        border-color: rgba($color-primary, 0.4);
        color: $color-primary;
      }
    }
  }
}
