/** 
 * Modal styles
 */

.modal {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: scale(0.94) !important;
  transition: opacity 200ms, transform 200ms !important;
  .modal-header,
  .modal-body,
  .modal-footer {
    opacity: 0;
    transition: opacity 300ms;
    transition-delay: 400ms;
  }
  &.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1) !important;
    .modal-header,
    .modal-body,
    .modal-footer {
      opacity: 1;
    }
  }
}

.modal-backdrop {
  opacity: 0 !important;
  transition: opacity 200ms;
  pointer-events: none;
  z-index: 100;
  &.visible {
    opacity: 0.5 !important;
    pointer-events: all;
  }
}

.modal-dialog {
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  z-index: 101;
  .modal-content {
    border: 0;
    border-radius: 6px;
    max-height: 85vh;
    transition: none !important;
    z-index: 101;
  }
  .modal-header {
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #f1f2f3;
    border-radius: 12px 12px 0 0 !important;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    &.thin {
      padding: 6px 20px;
    }
    .left,
    .right {
      flex-basis: 20%;
    }
    .center {
      flex-basis: 60%;
      display: flex;
      justify-content: center;
      .modal-title {
        margin: 0;
      }
    }
    .right {
      display: flex;
      justify-content: flex-end;
    }
  }
  .modal-title {
    font-size: 14px;
    font-weight: 500;
    margin: 4px 0 0;
  }
  .modal-body {
    overflow-y: scroll;
    padding: 20px;
  }
  .modal-footer {
    border-color: $color-border-light;
    padding: 12px 20px;
    > * {
      margin: 0;
    }
  }
}
.modal.wide {
  .modal-dialog {
    max-width: 800px !important;
  }
}

#product-modal {
  .modal-content {
    min-height: 75vh;
  }
}
