.nav-pills .nav-link {
  color: #0c1326;
  display: block;
  font-weight: 500;
  padding: 0.4rem 0.6rem 0.35rem;
  &:focus {
    outline: none;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #3b54ec;
  background-color: rgba(59, 84, 236, 0.1);
}

.nav-tabs {
  border: 0;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-right: 0;
  margin-left: 0;
  // width: 100%;
  .nav-link {
    border: 0;
    border-bottom: 1px solid $color-border-light;
    color: $color-gray;
    padding: 0 0 8px;
    font-weight: 500;
    // width: 100%;
    text-align: center;
    transition: all 300ms ease-in-out;
    &:not(:last-child) {
      margin-right: 15px;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:after {
      content: ' ';
      position: relative;
      display: block;
      width: 40%;
      min-width: 30px;
      height: 3px;
      border-radius: 10px 10px 0 0;
      background: transparent;
      margin: 0 auto;
      bottom: -11px;
      transition: all 300ms ease-in-out;
    }
    &.active {
      border-color: $color-border-light;
      color: $color-primary;
      opacity: 1;
      &:after {
        background: $color-primary;
        bottom: -8px;
      }
    }
  }
  &.light .nav-link {
    color: $color-gray;
    border-color: $color-secondary;
    border-width: 4px;
    &:hover {
      color: #fff;
    }
    &.active {
      background: transparent;
      border-color: $color-accent;
      color: $color-accent;
      opacity: 1;
    }
  }
}

.nav.card-header-pills.nav-tabs,
.nav.card-header-pills.nav-pills {
  overflow-x: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
}
